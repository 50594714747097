/* eslint-disable react-hooks/exhaustive-deps */
import useDeleteItems from "../../../hooks/useDeleteItems";
import useToast from "../../../hooks/useToast";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { AuthContext } from "../../../context/AuthContext";
import Like from "./PostActionItems/Like";
import Bookmark from "./PostActionItems/Bookmark";
import Share from "./PostActionItems/Share";
import dateFormatter from "../../../utils/dateFormatter";
import comment from "../../../assets/comment.svg";
import kebabIcon from "../../../assets/kebab-vertical.svg";
import location from "../../../assets/location.svg";
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import "./PostItem.css";

const PostItem = (props) => {
  const themeCtx = useContext(ThemeContext);
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const showToast = useToast();
  const [postActionState, setPostActionState] = useState(false);

  const deletePost = useDeleteItems(
    `${process.env.REACT_APP_BASE_API_URL}/posts/${props.id}/`,
    true
  );

  const sendDelete = () => {
    deletePost.mutate();
  };

  useEffect(() => {
    if (deletePost.isSuccess) {
      props.refetch();
      showToast("Deleted Successfully", true);
      navigate("/");
    }
  }, [deletePost.isSuccess]);

  const openGoogleMap = () => {
    if (props.mapLink) window.open(`${props.mapLink}`, "_blank");
  };

  const goToUserProfile = () => {
    navigate(`/user/${props.uid}`);
  };

  const goToPost = () => {
    navigate(`/post/${props.id}`);
  };

  return (
    <div className="post-item">
      <div className="post-header">
        <img
          src={props.author.profileImage}
          onClick={goToUserProfile}
          alt="profileImage"
        />
        <div className="post-user-details" onClick={goToUserProfile}>
          <span>{props.author.name}</span>
          <span>@{props.uid}</span>
        </div>
        <div className="post-date">{dateFormatter(props?.createdAt)}</div>

        {authCtx.userData && authCtx.userData.uid === props.uid && (
          <img
            src={kebabIcon}
            alt="kebab-menu"
            className="kebab-menu"
            onClick={() => setPostActionState((prevState) => !prevState)}
            data="Delete"
            style={{
              filter: themeCtx.isDarkMode
                ? "invert(81%) sepia(3%) saturate(8%) hue-rotate(315deg) brightness(86%) contrast(80%)"
                : "none",
            }}
          />
        )}
        {postActionState && (
          <div className="post-actions">
            <div className="action-item">
              <MdModeEditOutline size={18} /> Edit
            </div>
            <div className="action-item" onClick={sendDelete}>
              <MdDelete size={18} /> Delete
            </div>
          </div>
        )}
      </div>
      <img
        className="post-image"
        src={props.image}
        onClick={goToPost}
        alt="postMainImage"
      />
      <div className="post-desc">
        <span>{props.title}</span>
        <div className="location" onClick={openGoogleMap}>
          <img
            src={location}
            alt=""
            style={{
              filter: themeCtx.isDarkMode
                ? "invert(81%) sepia(3%) saturate(8%) hue-rotate(315deg) brightness(86%) contrast(80%)"
                : "none",
              height: "28px",
            }}
          />
          <span>{props.location}</span>
        </div>
        <span>{props.desc}</span>
      </div>
      <div className="user-actions">
        <Like likes={props.likes} id={props.id} />
        <div className="comment" onClick={goToPost}>
          <img
            src={comment}
            alt=""
            style={{
              filter: themeCtx.isDarkMode
                ? "invert(81%) sepia(3%) saturate(8%) hue-rotate(315deg) brightness(86%) contrast(80%)"
                : "none",
            }}
          />
          <span>{props.comments.length}</span>
        </div>
        <Bookmark id={props.id} uid={props.uid} saves={props.saves} />
        <Share id={props.id} />
      </div>
    </div>
  );
};

export default PostItem;
