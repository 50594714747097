/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../shared/components/Loading/Loading";
import PostItem from "../../shared/components/PostItem/PostItem";
import useGetItems from "../../hooks/useGetItems";
import MainPageLayout from "../../shared/components/MainPageLayout/MainPageLayout";
import sad from "../../assets/sad.svg";
import "./Feed.css";
import useToast from "../../hooks/useToast";

const Feed = () => {
  const showToast = useToast();
  const [sortState, setSortState] = useState(true);
  const feedData = useGetItems(
    `${process.env.REACT_APP_BASE_API_URL}/posts/feed`,
    true
  );

  useEffect(() => {
    if (feedData.isError) {
      showToast("Something went wrong", false);
    }
  }, [feedData.isError]);

  return (
    <>
      <MainPageLayout active="home">
        {feedData.isLoading && <Loading />}
        {!feedData.isLoading && (
          <>
            <div className="sort-buttons">
              <div
                className={sortState ? "active" : ""}
                onClick={() => setSortState(true)}
              >
                ⚡ Trending
              </div>
              <div
                className={!sortState ? "active" : ""}
                onClick={() => setSortState(false)}
              >
                📆 Latest
              </div>
            </div>
          </>
        )}
        {!feedData.isLoading &&
          feedData.data.length !== 0 &&
          feedData.data.posts
            .slice()
            .sort(
              (
                { likes: { length: a }, createdAt: c },
                { likes: { length: b }, createdAt: d }
              ) =>
                sortState
                  ? b - a
                  : new Date(d.toString()) - new Date(c.toString())
            )
            .map((post) => {
              return (
                <PostItem
                  key={post._id}
                  id={post._id}
                  saves={feedData.data.saves}
                  {...post}
                />
              );
            })}

        {!feedData.isLoading && (
          <div className="feed-end">
            <img src={sad} alt="" />
            <span>{`${
              feedData.data.posts.length === 0 ? "No " : "End of "
            }content?`}</span>
            <span>
              {`${
                feedData.data.posts.length === 0
                  ? "Find and follow "
                  : "For more content, find new "
              }`}
              users on the <Link to="/explore">explore</Link> page
            </span>
          </div>
        )}
      </MainPageLayout>
    </>
  );
};

export default Feed;
